@import ../../../styles/helpers

.details
    position: relative
    margin-top: -120px

.container
    width: 100%
    display: flex
    max-width: 1280px
    margin: 40px auto
    padding: 0 20px
    +dark
        background-color: #2c2f38
        color: #e5e5e5
    +m
        flex-direction: column
        padding: 10px

.leftContainer
    flex: 1
    padding-right: 20px
    border-right: 1px solid $n8
    +dark
        border: none
    +m
        padding-right: 0
        border: none

.price
    +caption-bold-1 
    font-weight: 600

.rightContainer
    flex: 2
    padding-left: 20px
    +dark
        background-color: #1e2226

    .header
        +caption-bold-1 
        font-weight: 600
    +m
        margin-top: 20px
        padding-left: 0
        flex: 1

.historicalPrice
    margin-top: 30px
    .header
        margin-bottom: 10px
    .priceItem
        display: flex
        justify-content: space-between
        margin-bottom: 12px
        padding: 10px
        background-color: #f9f9f9
        border-radius: 6px
        +dark
            background-color: #333
        .key
            +caption-1
            font-weight: 500
            +dark
                color: $n6
        .value
            +caption-1
            +dark
                color: $n6
    +m
        .priceItem
            padding: 8px

.information
    +body-bold-1
    margin-bottom: 16px
    .buttonContainer
        display: flex
        gap: 10px
        justify-content: flex-start  
        flex-wrap: wrap

    .button
        padding: 10px 16px
        border-radius: 6px
        border: 1px solid #dcdcdc
        background-color: #f0f0f0
        cursor: pointer
        transition: background-color 0.3s
        &:hover
            background-color: #e1e1e1
        +dark
            background-color: #555
            border-color: #666
            color: #e5e5e5
            &:hover
            background-color: #666
    .tradeButton
        padding: 10px 16px
        border-radius: 6px
        color: $n7
        border: 1px solid #dcdcdc
        background-color: #22767d
        cursor: pointer
        transition: background-color 0.3s
        &:hover
            background-color: #22767d
        +dark
            background-color: #555
            border-color: #666
            color: #e5e5e5
            &:hover
            background-color: #22767d
    +m
        .button
            padding: 8px 12px
            font-size: 14px

.chartContainer
    margin: 10px 0

.timeIntervalContainer 
    border: 1px solid #e5e7eb
    border-radius: 12px
    overflow: hidden
    margin-bottom: 20px

    .timeIntervalTable
        width: 100%
        border-radius: 12px
        overflow: hidden        
    
    .tableHeader
        border-bottom: 1px solid #e5e7eb
        background-color: #f3f4f6
        color: #1f2937
        font-size: 0.75rem
        text-align: center
        +dark
            background: $n2
            color: #fff
    
    .headerCell
        padding: 12px 8px
        font-weight: 600
        text-align: center
        white-space: nowrap

        &:first-child 
        padding-left: 16px

        &:last-child
        padding-right: 16px

    .tableBody
        .tableRow 
            border-bottom: 1px solid #e5e7eb

        .tableCell
            padding: 12px 8px
            text-align: center
        .geckoUp,
        .geckoDown 
            font-size: 0.875rem
            display: inline-flex
            align-items: center

        .geckoUp
            color: #16a34a

        .geckoDown 
            color: #dc2626

            &:first-child 
                padding-left: 16px
          
            &:last-child
                padding-right: 16px
          
.questions
    margin-top: 30px


.description 
    +caption-bold-1 
    font-weight: 600
    margin-bottom: 1rem

.name
    font-weight: bold
    color: #2d3e50

.slug
    color: #6c757d

.aboutLine
    padding: 10px 15px
    background-color: #f4f4f4
    border-radius: 6px
    margin-bottom: 5px
    word-wrap: break-word
    white-space: pre-line
    transition: background-color 0.3s ease
    +dark
        color: $n8
        background-color: #2c2f38

.infoHeading
    margin: 10px 0