@import ../../../styles/helpers

.form
    max-width: 480px
    margin: 0 auto

.title
    margin-bottom: 32px

.field
    margin-bottom: 12px

.button
    width: 100%

.danger
    color: red
    font-size: 12px

.input
    margin-bottom: 10px

.customDatePicker
  width: 100%
  padding: 10px
  border: 2px solid #E6E8EC
  border-radius: 5px
  font-size: 16px

  &:focus
    border-color: #4d90fe
