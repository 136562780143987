.whatsappButtonContainer
    position: fixed
    bottom: 20px
    right: 20px
    z-index: 1000

.whatsappButtonContainer img
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2)
    cursor: pointer
    width: 64px
    height: 64px