@import ../../../styles/helpers

.head,
.preview
    padding-bottom: 10px
    +dark
        border-color: $n6

.title
    position: 40px

.field
    position: relative
    margin-bottom: 24px

.icon
    position: absolute
    top: 0
    left: 0
    bottom: 0
    display: flex
    justify-content: center
    align-items: center
    width: 40px
    pointer-events: none
    svg
        fill: $n4

.input
    width: 100%
    height: 40px
    padding: 0 60px 0 44px
    border-radius: 8px
    background: $n2
    font-size: 15px
    font-weight: 600
    color: $n6
    +dark
        background: $n6

.copy
    position: absolute
    top: 0
    right: 8px
    bottom: 0
    font-size: 15px
    font-weight: 600
    line-height: 40px
    color: $p1
    transition: opacity .2s
    &:hover
        opacity: .8

.preview
    position: relative
    flex-shrink: 0
    overflow: hidden
    &:hover
        cursor: pointer
        +rmin(768)
            background: $n2
            +dark
                background: $n6
    

.btns
    display: flex
    margin: 0 -8px
    .button
        flex-grow: 1
        margin: 0 8px

   
.col
    word-wrap: break-word
    vertical-align: top
    +t
        display: flex
        align-items: center
        padding: 0
    &:not(:first-child)
        +t
            padding-top: 0
    &:not(:last-child)
        padding-right: 24px
        +t
            margin-bottom: 12px
            padding-top: 0

.item
    margin-top: 10px
    display: flex
    align-items: center
    
.details 
    margin-left: 12px

.wrapper
    border-bottom: 1px solid $n3
    padding: 10px 0

.text
    font-weight: 500
    color: $n4

.distribution
    font-size: 15px
    font-weight: 600
    color: $n7

.image
    width: 100px
    height: 100px
    border-radius: 50px

.img
    width: 80px
    height: 80px

.image
    &:hover
        cursor: pointer
        +rmin(768)
            background: $n2
            +dark
                background: $n6