@import ../../styles/helpers

.head
    padding: 20px 32px 8px
    +caption-2
    font-weight: 500
    color: $n4
    +m
        padding: 20px 0 8px

.body
    background: $n8
    border-radius: 4px
    //overflow: hidden
    +dark
        background: #17181B

.button
    height: 32px
    +m
        flex-grow: 1
        padding: 0
    &:not(:last-child)
        margin-right: 8px